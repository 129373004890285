import { RequestCard } from "./RequestCard/RequestCard.tsx";
import { useContext, useMemo, useState } from "react";
import { DataContext } from "../../dataProviders/DataProvider.tsx";
import { QueryError } from "../../components/QueryError.tsx";
import { Loader } from "../../components/Loader/Loader.tsx";
import { TransactionsDataContext } from "../../dataProviders/TransactionsDataProvider.tsx";
import { t, Trans } from "@lingui/macro";
import { CONFIG } from "../../utils/config.ts";

export function Requests() {
  const { areAccountQueriesPending, areAccountQueriesError, requests } = useContext(DataContext);
  const { areTransactionsQueriesPending, areTransactionsQueriesError, transactionRequest } =
    useContext(TransactionsDataContext);

  const [selectedBank, setSelectedBank] = useState<string>(requests.accounts[0]?.paymentProvider ?? "");
  const [selectedAccount, setSelectedAccount] = useState<string>(transactionRequest[0]?.accountId || "");

  const selectBank = useMemo(() => {
    if (!requests.accounts.length) return [];

    setSelectedBank(requests.accounts[0]?.paymentProvider);
    return requests.accounts.map(({ paymentProvider, label }) => ({ value: paymentProvider, label }));
  }, [requests]);

  const selectAccount = useMemo(() => {
    if (!transactionRequest.length) return [];

    setSelectedAccount(transactionRequest[0]?.accountId);
    return transactionRequest.map(({ accountId, label }) => ({ value: accountId, label }));
  }, [transactionRequest]);

  const accountsResponse = useMemo(
    () => requests.accounts.find(({ paymentProvider }) => paymentProvider === selectedBank)?.response ?? "",
    [requests, selectedBank],
  );

  const transactionsResponse = useMemo(
    () => transactionRequest.find(({ accountId }) => accountId === selectedAccount)?.response ?? "",
    [transactionRequest, selectedAccount],
  );

  const paymentProviderVariable = selectedBank || "<paymentProvider>";
  const bankAccountIdVariable = selectedAccount || "<bankAccountId>";

  const { API_URL } = CONFIG;
  let apiEnv;
  if (API_URL.includes('dev')) {
    apiEnv = 'dev.';
  } else if (API_URL.includes('sandbox')) {
    apiEnv = 'sandbox.';
  } else {
    apiEnv = '';
  }
  const accountsRequest = `curl --location 'https://api.${apiEnv}finbricks.com/account/listWithBalance?merchantId=<your-merchant-id>&clientId=<this-user-id>&paymentProvider=${paymentProviderVariable}' \\
--header 'Accept: application/json' \\
--header 'JWS-Signature: <jws-token-generated-with-private-key>'`;

  const transactionsRequest = `curl --location 'https://api.${apiEnv}finbricks.com/account/transactions?merchantId=<your-merchant-i>d&clientId=<this-user-id>&paymentProvider=${paymentProviderVariable}&bankAccountId=${bankAccountIdVariable}&size=50' \\
--header 'Accept: application/json' \\
--header 'JWS-Signature: jws-token-generated-with-private-key'`;

  const connectBankRequest = `curl --location 'https://api.${apiEnv}finbricks.com/v2/auth/authenticate' \\
--header 'Content-Type: application/json' \\
--header 'Accept: application/json' \\
--header 'JWS-Signature: <jws-token-generated-with-private-key>' \\
--header 'PSU-IP-Address: 127.0.0.1' \\
--header 'PSU-User-Agent: Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0) Gecko/20100101 Firefox/47.0' \\
--data '{
    "merchantId": <your-merchant-id>,
    "clientId": <this-user-id>,
    "paymentProvider": ${paymentProviderVariable},
    "scope": "AISP",
    "callbackUrl": "https://${apiEnv}multibank.cz/requests"
}'`;

/*
  const bankProvidersDescription = t`Seznam všech bank, které mohu nabídnout k propojení klientovi + metainformace o každé bance (např. logo či celý název).`;

  const bankProvidersRequest = `curl --location 'https://api.${apiEnv}finbricks.com/status/bankInfo?merchantId=<your-merchant-id>&enabledForMerchant=true' \\
--header 'Accept: application/json' \\
--header 'JWS-Signature: <jws-token-generated-with-private-key>'`;
*/

  const tokensRequest = `curl --location 'https://api.${apiEnv}finbricks.com/auth/token/?merchantId=<your-merchant-id>&clientId=<this-user-id>' \\
--header 'Accept: application/json' \\
--header 'JWS-Signature: <jws-token-generated-with-private-key>'`;

  const accountsDescription = t`Seznam všech bankovních účtů u dané banky. Parametr "paymentProvider" získáme z response výše "Platné tokeny". (Volání se opakuje pro každou banku zvlášť). Všechny response můžete vidět v developer tools prohlížeče - backend této služby odpověď z Finbricks API nijak nemodifikuje.`;

  const transactionsDescription = t`Transakční historie pro daný účet. (Volá se pro každý účet zvlášť, max 50 transakcí na jeden request, do historie je možné stránkovat). Parametr "bankAccountId" získáme z response výše "Seznam účtů" - z "id" účtu. Všechny response můžete vidět v developer tools prohlížeče - backend této služby odpověď z Finbricks API nijak nemodifikuje.`;

  const connectBankDescription = t`Pro připojení další banky stačí zavolat jediný endpoint a přesměrovat uživatele na redirectUrl který je v response - tam se uživatel přihlásí k bance a potvrdí schválení přístupu a je přesměrován zpět na callbackUrl zadanou v Request Body.`;

  const connectBankResponse = `{
    "redirectUrl": "https://service.${apiEnv}finbricks.com/page/start?flowId=ae9d4916-49a4-4073-9e81-d13eb4c96b47"
}`;

  if (areAccountQueriesError)
    return <QueryError message={t`Nepodařilo se načíst informace o Vašich účtech`} grow={true} />;
  if (areAccountQueriesPending) return <Loader grow={true} message={t`Načítání informací o bankách`} />;
  if (areTransactionsQueriesError)
    return <QueryError message={t`Nepodařilo se načíst informace o Vašich transakcích`} grow={true} />;
  if (areTransactionsQueriesPending) return <Loader grow={true} message={t`Načítání informací o transakcích`} />;

  return (
    <div>
      <div className={"app-container"}>
        <h2 className={"typo-display-lg-bold text-gray-900 mb-4 mt-14"}>
          <Trans>Finbricks endpointy</Trans>
        </h2>
        <div>
          <p className={"typo-text-lg-regular mb-8"}>
            <Trans>
              Všechny endpointy Finbricks, které tento web používá, jsou zde zdokumentovány. Část API Response obsahuje
              reálný response z Finbricks pro tohoto uživatele (backend pro tento web nijak odpověď z Finbricks
              nemodifikuje, je ale nutný kvůli generování JWS podpisu pomocí privátního klíče).
            </Trans>
          </p>
        </div>

        <div className={"flex flex-col gap-8 mb-14"}>
{/*
          <RequestCard
            title={t`Seznam poskytovatelů (bank)`}
            description={bankProvidersDescription}
            link={"https://docs.finbricks.com/#tag/SystemStatus/operation/statusPaymentProviders"}
            apiRequest={bankProvidersRequest}
            apiResponse={requests.paymentProviders}
          />
*/}
          <RequestCard
            title={t`Platné tokeny`}
            description={t`Zjistíme u kterých bank (poskytovatelů) má uživatel platné tokeny.`}
            link={"https://docs.finbricks.com/#tag/Auth/operation/getRefreshTokens"}
            apiRequest={tokensRequest}
            apiResponse={requests.tokens}
          />
          <RequestCard
            title={t`Seznam účtů`}
            description={accountsDescription}
            link={"https://docs.finbricks.com/#tag/BankAccountInformation/operation/accountListWithBalanceGet"}
            apiRequest={accountsRequest}
            apiResponse={accountsResponse}
            select={selectedBank ? selectBank : undefined}
            value={selectedBank}
            onChange={setSelectedBank}
            label={t`Vyberte banku`}
          />
          <RequestCard
            title={t`Seznam transakcí`}
            description={transactionsDescription}
            link={"https://docs.finbricks.com/#tag/BankAccountInformation/operation/accountTransactionsGet"}
            apiRequest={transactionsRequest}
            apiResponse={transactionsResponse}
            select={selectedAccount ? selectAccount : undefined}
            value={selectedAccount}
            onChange={setSelectedAccount}
            label={t`Vyberte účet`}
          />
          <RequestCard
            title={t`Připojení nové banky`}
            description={connectBankDescription}
            link={"https://docs.finbricks.com/#tag/Auth/operation/createAuthenticationV2"}
            apiRequest={connectBankRequest}
            apiResponse={connectBankResponse}
          />
        </div>
      </div>
    </div>
  );
}
