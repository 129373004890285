import { useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { Divider, ScrollArea } from "../../../../components";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { addDays, isWithinInterval, subWeeks } from "date-fns";
import { Box } from "@mui/material";
import { axisClasses, AxisConfig, BarChart, ChartsXAxisProps } from "@mui/x-charts";
import { axisColor, noDataMessage, primaryChartColor, secondaryChartColor, tickColor } from "../../utils/chartsInfo.ts";
import { formatWeek } from "../../../../utils/format.ts";
import { MultipleValuesChartTooltip } from "../MultipleValuesChartTooltip.tsx";
import { ChartLegend } from "../ChartLegend.tsx";
import { TransactionsDataContext } from "../../../../dataProviders/TransactionsDataProvider.tsx";
import { t, Trans } from "@lingui/macro";

type WeeklyIncomesAndExpenses = { incomes: number; expenses: number; weekStart: Date; weekEnd: Date; label: string };
type Dataset = { weeks: WeeklyIncomesAndExpenses[]; transactionCount: number };

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);
const FIRST_WEEK_START = addDays(subWeeks(TODAY, 12), 1);
const FIRST_WEEK_END = addDays(FIRST_WEEK_START, 6);
const NUMBER_OF_WEEKS = 11;

export function IncomesAndExpensesChart() {
  const { activeCurrency } = useContext(DataContext);
  const { transactionsOfActiveAccounts } = useContext(TransactionsDataContext);

  const dataset: Dataset = useMemo(() => {
    let transactionCount = 0;
    const weeks: WeeklyIncomesAndExpenses[] = [
      {
        incomes: 0,
        expenses: 0,
        weekStart: FIRST_WEEK_START,
        weekEnd: FIRST_WEEK_END,
        label: formatWeek(FIRST_WEEK_START, FIRST_WEEK_END),
      },
    ];
    if (!transactionsOfActiveAccounts.length) return { weeks: [], transactionCount: transactionCount };

    for (let i = 0; i < NUMBER_OF_WEEKS; i++) {
      const weekStart = addDays(weeks[0].weekEnd, 1);
      const weekEnd = addDays(weeks[0].weekEnd, 7);

      weeks.unshift({
        incomes: 0,
        expenses: 0,
        weekStart,
        weekEnd,
        label: formatWeek(weekStart, weekEnd),
      });
    }

    let currentWeekIndex = 0;

    const getCurrentWeek = (transactionDate: Date): WeeklyIncomesAndExpenses | undefined => {
      const currentWeek = weeks[currentWeekIndex];
      if (!currentWeek) return;

      if (
        isWithinInterval(transactionDate, {
          start: currentWeek.weekStart,
          end: currentWeek.weekEnd,
        })
      ) {
        return currentWeek;
      }

      if (currentWeekIndex === NUMBER_OF_WEEKS) {
        currentWeekIndex = 0;
        return;
      }

      currentWeekIndex++;
      return getCurrentWeek(transactionDate);
    };

    for (const transaction of transactionsOfActiveAccounts) {
      const currentWeek = getCurrentWeek(transaction.date);

      if (!currentWeek) continue;

      if (transaction.creditDebitIndicator === "CRDT") currentWeek.incomes += transaction.amount.value;
      if (transaction.creditDebitIndicator === "DBIT") currentWeek.expenses += transaction.amount.value;
      transactionCount++;
    }
    return { weeks: [...weeks].reverse(), transactionCount };
  }, [transactionsOfActiveAccounts]);

  const activeAccountsDataset = dataset.transactionCount ? dataset.weeks : [];

  return (
    <section className={"bg-gray-25 p-6 rounded-lg flex flex-col gap-6 border border-gray-200"}>
      <div className={"flex gap-4"}>
        <FontAwesomeIcon className={"w-5 h-5 text-brand-700"} icon={faChartSimple} />
        <h3 className={"typo-display-xs-semibold text-gray-900"}>
          <Trans>Příjmy a výdaje</Trans>
        </h3>
      </div>
      <Divider />
      <ScrollArea orientation={"horizontal"}>
        <Box sx={{ width: "100%", minWidth: "350px" }}>
          <ChartLegend
            legendItems={[
              { label: t`Příjmy`, color: secondaryChartColor },
              { label: t`Výdaje`, color: primaryChartColor },
            ]}
          />
          <BarChart
            dataset={activeAccountsDataset}
            xAxis={
              [{ scaleType: "band", dataKey: "label", categoryGapRatio: 0.5, barGapRatio: 0.5 }] as AxisConfig<
                "band",
                string,
                ChartsXAxisProps
              >[]
            }
            leftAxis={{ disableTicks: true, disableLine: true }}
            bottomAxis={{ disableTicks: true, stroke: tickColor }}
            grid={{ horizontal: true }}
            series={[
              { dataKey: "incomes", color: secondaryChartColor, label: t`Příjmy` },
              { dataKey: "expenses", color: primaryChartColor, label: t`Výdaje` },
            ]}
            sx={{
              [`.${axisClasses.line}`]: { stroke: axisColor },
              [`.${axisClasses.tickLabel}`]: { fill: tickColor },
            }}
            slotProps={{
              legend: { hidden: true },
              noDataOverlay: { message: noDataMessage() },
            }}
            slots={{
              axisContent: (props) => {
                if (typeof props.dataIndex !== "number") return null;
                const datasetItem = activeAccountsDataset[props.dataIndex];
                return (
                  <MultipleValuesChartTooltip
                    values={[
                      { value: datasetItem.incomes, color: secondaryChartColor },
                      { value: datasetItem.expenses, color: primaryChartColor },
                    ]}
                    label={datasetItem.label}
                    currency={activeCurrency}
                  />
                );
              },
            }}
            borderRadius={999}
            height={442}
          />
        </Box>
      </ScrollArea>
    </section>
  );
}
