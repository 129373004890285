import { ReactNode, useEffect, useState } from "react";
import { dynamicActivate, getPreferredLanguage } from "./utils.ts";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { cs } from "date-fns/locale/cs";
import { enGB as en } from "date-fns/locale/en-GB";
import { csCZ, enUS } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers";

interface Props {
  children: ReactNode;
}

export function LocalesProvider({ children }: Props) {
  const [isReady, setIsReady] = useState(false);
  const language = getPreferredLanguage();

  const isCzech = language === "cs";

  useEffect(() => {
    void dynamicActivate(language).then(() => setIsReady(true));
  }, [language]);

  return isReady ? (
    <I18nProvider i18n={i18n}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={isCzech ? cs : en}
        localeText={
          isCzech
            ? csCZ.components.MuiLocalizationProvider.defaultProps.localeText
            : enUS.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        {children}
      </LocalizationProvider>
    </I18nProvider>
  ) : null;
}
