import { Column } from "../../Transactions.tsx";
import { clsx } from "clsx";
import css from "../../Transactions.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { t } from "@lingui/macro";

interface Props {
  sort: string;
  sortTransactions: (column: Column) => void;
  sortBy?: string;
}

export function TransactionTableHeader({ sortBy, sort, sortTransactions }: Props) {
  const tableHeaderData: { column: Column; name: string; isSortable: boolean }[] = [
    { column: "account", name: t`Účet`, isSortable: true },
    { column: "date", name: t`Datum`, isSortable: true },
    { column: "direction", name: t`Směr`, isSortable: true },
    { column: "counterParty", name: t`Protistrana`, isSortable: true },
    { column: "identification", name: t`Identifikace`, isSortable: true },
    { column: "message", name: t`Zpráva`, isSortable: true },
    { column: "amount", name: t`Částka`, isSortable: true },
    { column: "currency", name: t`Měna`, isSortable: true },
  ];

  return (
    <thead>
      <tr className={clsx(css.tableHeaderRow)}>
        {tableHeaderData.map((column) => (
          <th key={column.name}>
            <button
              className={"flex gap-2 items-center typo-display-xxs-semibold p-4"}
              onClick={() => sortTransactions(column.column)}
            >
              {column.name}
              <div className={"flex flex-col"}>
                <FontAwesomeIcon
                  className={clsx(
                    "w-[0.625rem] -m-[5px] ml-0.5 text-gray-300",
                    column.column === sortBy && sort === "asc" ? "!text-brand-700" : "",
                  )}
                  icon={faCaretUp}
                />
                <FontAwesomeIcon
                  className={clsx(
                    "w-[0.625rem] -m-[5px] ml-0.5 text-gray-300",
                    column.column === sortBy && sort === "desc" ? "!text-brand-500" : "",
                  )}
                  icon={faCaretDown}
                />
              </div>
            </button>
          </th>
        ))}
      </tr>
    </thead>
  );
}
