import { assert, object, type } from "superstruct";
import { i18n, Messages } from "@lingui/core";

const LANGUAGE_STORAGE_KEY = "@fin/lang";
export type Locale = "cs" | "en";

export const defaultLocale: Locale = "en";
export async function dynamicActivate(locale: Locale = defaultLocale) {
  const module: unknown = await import(`./messages/${locale}.po?lingui`);
  assert(module, type({ messages: object() }));
  i18n.load(locale, module.messages as Messages);
  i18n.activate(locale);
}

export function setPreferredLanguage(language: Locale): void {
  localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
}

export function getPreferredLanguage() {
  const saved = localStorage.getItem(LANGUAGE_STORAGE_KEY) as Locale;

  const browserLanguage = navigator.language === "cs" ? "cs" : "en";

  const language = saved ?? browserLanguage ?? "en";

  if (!saved) setPreferredLanguage(language);

  return language;
}
