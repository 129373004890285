import { clsx } from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeteor } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button/Button.tsx";
import { t } from "@lingui/macro";
import { useContext } from "react";
import { DataContext } from "../dataProviders/DataProvider.tsx";
import { useRevokeProvider } from "../hooks/useRevokeProvider.ts";
interface Props {
  message: string;
  grow: boolean;
  className?: string;
}

export function QueryError({ message, grow, className }: Props) {
  const { accountQueryInvalidProviders, paymentProviders } = useContext(DataContext);

  const revokeBankProvider = useRevokeProvider(true);

  return (
    <div className={clsx("flex flex-col py-12 px-28 items-center justify-center gap-8", grow && "grow", className)}>
      <FontAwesomeIcon className={"h-7 text-error-600 animate-bounce"} icon={faMeteor} />
      <p className={"typo-display-sm-medium text-center text-gray-900"}>{message}</p>
      <Button variant={"default"} color={"secondary"} size={"lg"} onClick={() => window.location.reload()}>
        {t`Obnovit stránku`}
      </Button>
      {!!accountQueryInvalidProviders.length && (
        <>
          <p className={"typo-display-sm-medium text-center text-gray-900"}>{t`nebo`}</p>
          {accountQueryInvalidProviders.map((provider) => {
            const name =
              paymentProviders.find(({ identification }) => identification.paymentProvider === provider)?.identification
                .name ?? provider;
            return (
              <Button variant={"default"} color={"secondary"} size={"lg"} onClick={() => revokeBankProvider(provider)}>
                {t`Odpojit banku ${name}`}
              </Button>
            );
          })}
        </>
      )}
    </div>
  );
}
