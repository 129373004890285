import { Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthorizeProviderMutation } from "../../../../api/hooks/useAuthorizeProviderMutation.ts";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { Card, Modal, Tabs, Flag, ScrollArea, Input, Button, Tooltip } from "../../../../components";
import { getNameFromCountryCode } from "../../../../utils/getLocale.ts";
import { PaymentProvidersResponse } from "../../../../api";
import css from "./AddBankModal.module.css";
import { clsx } from "clsx";
import { DialogProps } from "@radix-ui/react-dialog";
import { useToast } from "../../../../components/Toast/useToast.ts";
import { logError } from "../../../../utils/sentry.ts";
import { sortCountries } from "../../utils/sortCountries.ts";
import { useForm } from "react-hook-form";
import { isValid } from "iban";
import { t, Trans } from "@lingui/macro";
import infoIcon from "../../../../assets/info.svg";

interface Props extends Pick<DialogProps, "open" | "onOpenChange"> {
  open?: boolean;
  onOpenChange?: Dispatch<SetStateAction<boolean>>;
  trigger?: ReactNode;
}

type Input = {
  iban: string;
  psuid: string;
};

type StructuredProviders = Record<string, PaymentProvidersResponse["banks"]>;

const PROVIDERS_DEMANDING_IBAN = ["KH_HU", "RAIFFEISEN_HU", "EBH_HU", "CIB_HU", "RAIFFEISEN_RO"];
const PROVIDERS_DEMANDING_PSUID = ["RAIFFEISEN_HU", "EBH_HU", "CIB_HU", "RAIFFEISEN_RO", "BRD_RO"];
const shouldDisplaySecondStep = (paymentProvider: string, iban?: string, psuid?: string) => {
  return (
    !iban &&
    !psuid &&
    (PROVIDERS_DEMANDING_IBAN.includes(paymentProvider) || PROVIDERS_DEMANDING_PSUID.includes(paymentProvider))
  );
};

export function AddBankModal({ open, onOpenChange, trigger }: Props) {
  const { paymentProviders } = useContext(DataContext);

  const { showToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>();

  const [providerDemandingData, setProviderDemandingData] = useState<string>();
  const providerDemandingDataObject = useMemo(() => {
    return paymentProviders.filter((provider) => provider.identification.paymentProvider === providerDemandingData);
  }, [paymentProviders, providerDemandingData]);
  const shouldDisplayIbanInput = PROVIDERS_DEMANDING_IBAN.includes(providerDemandingData ?? "");
  const shouldDisplayPsuidInput = PROVIDERS_DEMANDING_PSUID.includes(providerDemandingData ?? "");

  const authorizeMutation = useAuthorizeProviderMutation();

  const infoMap: Record<string, string> = {
    BRD_RO: t`Klientské ID (známé jako YOU BRD login ID) je 8místné číslo používané pro přihlášení do mobilní a internetové aplikace BRD. Toto ID lze vyhledat v mobilní nebo internetové aplikaci výběrem možnosti „Autentificare in Mobile Token“ nebo kontaktováním BRD na čísle +4 021 302 6161.`,
    RAIFFEISEN_RO: t`Klientské ID (známé jako PSU-ID nebo PSU-Corporate-ID) je uživatelské jméno používané k přihlášení do aplikace banky. Uživatelské jméno najdete v aplikaci banky v pravém horním rohu hlavního panelu > SPRÁVCE PROFILU UŽIVATELE > SPRÁVA PROFILU > UŽIVATELSKÁ DATA.`,
  };

  const authorizeBankProvider = async (paymentProvider: string, iban?: string, psuId?: string) => {
    if (shouldDisplaySecondStep(paymentProvider, iban, psuId)) return setProviderDemandingData(paymentProvider);

    try {
      const { redirectUrl } = await authorizeMutation.mutateAsync({
        psuId,
        paymentProvider,
        accountIdentifications: iban ? [{ type: "IBAN", value: iban }] : undefined,
        redirectUrl: window.location.protocol + "//" + window.location.host + window.location.pathname,
      });
      window.location.href = redirectUrl;
    } catch (e) {
      showToast({ variant: "attention", title: t`Připojení banky se nezdařilo`, description: t`Zkuste to znovu.` });
    }
  };

  const { structuredProviders, countryKeys } = useMemo(() => {
    const structuredProviders: StructuredProviders = {};
    try {
      for (let i = 0; i < paymentProviders.length; i++) {
        const country = paymentProviders[i].identification.countryCode;
        if (!structuredProviders[country]) structuredProviders[country] = [];
        structuredProviders[country] = [...structuredProviders[country], paymentProviders[i]];
      }
      const countryKeys = Object.keys(structuredProviders);

      const sortedCountries = sortCountries(countryKeys);

      return { structuredProviders, countryKeys: sortedCountries };
    } catch (error) {
      logError(error);
      return { structuredProviders, countryKeys: [] };
    }
  }, [paymentProviders]);

  const [activeCountry, setActiveCountry] = useState(Object.keys(structuredProviders)[0]);

  const hasPsuidInfo = providerDemandingData && Object.keys(infoMap).includes(providerDemandingData);

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      trigger={trigger}
      title={t`Výběr banky pro synchronizaci`}
      className={clsx(!providerDemandingData && "min-h-[32.1rem]")}
      icon={
        <FontAwesomeIcon
          className={css.icon}
          icon={faBuildingColumns}
          width={"22px"}
          height={"22px"}
          color={"#3518ec"}
        />
      }
    >
      {!providerDemandingData && (
        <>
          <ScrollArea orientation={"horizontal"}>
            <div className={clsx(css.boxContainer, "pb-2")}>
              {countryKeys.map((key, index) => (
                <Tabs
                  onClick={() => setActiveCountry(key)}
                  key={key + String(index)}
                  isActive={activeCountry === key}
                  variant={"box"}
                  startIcon={<Flag code={key} />}
                >
                  {getNameFromCountryCode(key)}
                </Tabs>
              ))}
            </div>
          </ScrollArea>
          <ScrollArea orientation={"vertical"} className={"max-h-[350px] mt-6"}>
            <div className={"flex gap-3 flex-wrap"}>
              {structuredProviders[activeCountry].map(({ identification }) => (
                <button
                  key={identification.paymentProvider}
                  title={identification.name}
                  onClick={() => authorizeBankProvider(identification.paymentProvider)}
                  className={"block"}
                >
                  <Card key={identification.paymentProvider} variant={"light"} size={"sm"}>
                    <img
                      src={identification.logoUrl}
                      alt={identification.name}
                      className={"w-28 h-16 object-contain"}
                    />
                  </Card>
                </button>
              ))}
            </div>
          </ScrollArea>
        </>
      )}
      {providerDemandingData && (
        <div>
          <p className={"mb-6 typo-display-xxs-medium"}>
            <Trans>
              Banka{" "}
              <span className={"typo-display-xxs-bold"}>{providerDemandingDataObject[0].identification.name}</span>{" "}
              vyžaduje pro připojení tyto informace:
            </Trans>
          </p>

          <form onSubmit={handleSubmit((data) => authorizeBankProvider(providerDemandingData, data.iban, data.psuid))}>
            <div className={"flex flex-col gap-4 mb-8"}>
              {shouldDisplayPsuidInput && (
                <div className={"flex items-center gap-4"}>
                  <Input
                    label={t`ID klienta`}
                    required={true}
                    containerProps={{ className: "w-full max-w-sm" }}
                    className={"min-w-[16rem] md:min-w-[20rem]"}
                    {...register("psuid", {
                      required: t`Toto pole je povinné`,
                    })}
                    isError={!!errors.psuid?.message}
                    messages={errors.psuid?.message ? [{ variant: "error", text: errors.psuid.message }] : undefined}
                  />
                  {hasPsuidInfo && (
                    <Tooltip
                      side={"right"}
                      trigger={<img src={infoIcon} alt={"info"} className={"mt-3.5 w-5 h-5"} />}
                      text={infoMap[providerDemandingData]}
                    />
                  )}
                </div>
              )}
              {shouldDisplayIbanInput && (
                <Input
                  label={"IBAN"}
                  required={true}
                  containerProps={{ className: "w-full max-w-sm" }}
                  className={"min-w-[16rem] md:min-w-[20rem]"}
                  {...register("iban", {
                    required: t`Toto pole je povinné`,
                    validate: (value) => isValid(value) || t`IBAN není v platném formátu`,
                  })}
                  isError={!!errors.iban?.message}
                  messages={errors.iban?.message ? [{ variant: "error", text: errors.iban.message }] : undefined}
                />
              )}
            </div>

            <div className={"flex items-center justify-end gap-6"}>
              <Button
                variant={"default"}
                color={"gray"}
                size={"sm"}
                type={"button"}
                onClick={() => setProviderDemandingData(undefined)}
              >
                <Trans>Zpět</Trans>
              </Button>
              <Button variant={"default"} color={"primary"} size={"md"} type={"submit"}>
                <Trans>Připojit banku</Trans>
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
}
