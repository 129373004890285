import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../components";
import css from "./NoAvailableBanksPage.module.css";
import { AddBankModal } from "../Dashboard/components/AddBankModal/AddBankModal.tsx";
import { Title } from "../../components/Title/Title.tsx";
import { Trans } from "@lingui/macro";
import { useState } from "react";

export function NoAvailableBanksPage() {
  const [open, setOpen] = useState(false);

  return (
    <div className={css.container}>
      <Title />
      <section className={css.addBankSection}>
        <p className={css.text}>
          <Trans>Zatím nemáte napojenou žádnou banku.</Trans>
        </p>
        <AddBankModal
          key={open ? 1 : 0}
          open={open}
          trigger={
            <Button variant={"default"} color={"primary"} size={"lg"} endIcon={<FontAwesomeIcon icon={faPlus} />}>
              <Trans>Přidat banku</Trans>
            </Button>
          }
          onOpenChange={setOpen}
        />
      </section>
    </div>
  );
}
