import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faCircle, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Divider, Tabs } from "../../../../components";
import { LastTransactionsTable } from "./LastTransactionsTable.tsx";
import { NavLink } from "react-router-dom";
import { ERoutes } from "../../../../router/routes.ts";
import css from "../../../Transactions/Transactions.module.css";
import { Trans, t } from "@lingui/macro";

export type Direction = "all" | "in" | "out";
type TabData = { value: Direction; text: string }[];

export function LastTransactions() {
  const tabData: TabData = [
    { value: "all", text: t`Všechny` },
    { value: "in", text: t`Příchozí` },
    { value: "out", text: t`Odchozí` },
  ];

  const [direction, setDirection] = useState<Direction>("all");
  return (
    <section className={css.cardLastTransaction}>
      <div className={"flex gap-4"}>
        <FontAwesomeIcon className={"w-5 h-5 text-brand-700"} icon={faList} />
        <h3 className={"typo-display-xs-semibold text-gray-900"}>
          <Trans>Poslední transakce</Trans>
        </h3>
      </div>
      <div className={"flex gap-3 flex-wrap"}>
        {tabData.map((tab) => (
          <Tabs
            key={tab.value}
            startIcon={<FontAwesomeIcon className={"w-1.5 pt-0.5"} icon={faCircle} />}
            variant={"box"}
            onClick={() => setDirection(tab.value)}
            isActive={direction === tab.value}
          >
            {tab.text}
          </Tabs>
        ))}
      </div>
      <Divider />

      <LastTransactionsTable direction={direction} />
      <div className={"text-right"}>
        <NavLink to={ERoutes.Transactions} className={css.link}>
          <p>
            <Trans>Zobrazit všechny</Trans>
          </p>
          <FontAwesomeIcon className={"h-5"} icon={faChevronRight} />
        </NavLink>
      </div>
    </section>
  );
}
