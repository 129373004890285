import { useMutation } from "@tanstack/react-query";
import {
  ApiError,
  ProviderAuthorizeInput,
  ProviderAuthorizeResponse,
  validateProviderAuthorizeResponse,
} from "../types.ts";
import { api } from "../ky.ts";
import { getApiUrl } from "../utils.ts";

export function useAuthorizeProviderMutation() {
  return useMutation<ProviderAuthorizeResponse, ApiError, ProviderAuthorizeInput>({
    mutationFn: async (json) => {
      return validateProviderAuthorizeResponse(
        await api.post(getApiUrl("/multibank/users/me/providers/authorize"), { json }).json(),
      );
    },
  });
}
