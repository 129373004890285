import { format } from "date-fns";
import { getPreferredLanguage } from "../locales/utils.ts";

const language = getPreferredLanguage();
const isCzech = language === "cs";

export const formatNumber = (amount: number) => {
  return isNaN(amount)
    ? ""
    : new Intl.NumberFormat(isCzech ? "cs-CZ" : "en-GB", { minimumFractionDigits: 2 }).format(amount);
};

export function formatDay(value: Date): string {
  return format(value, isCzech ? "d.M.yy" : "d/M/yy");
}

export function formatDayWithFullYear(value: Date): string {
  return format(value, isCzech ? "d.M.yyyy" : "d/M/yyyy");
}

export function formatWeek(start: Date, end: Date): string {
  return `${format(start, isCzech ? "d.M." : "d/M")}–${format(end, isCzech ? "d.M." : "d/M")}`;
}
