import { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "@lingui/macro";
import { useToast } from "../components/Toast/useToast.ts";
import { useRevokeProviderMutation } from "../api/hooks/useRevokeProviderMutation.ts";
import { DataContext } from "../dataProviders/DataProvider.tsx";
import { TOKENS_QUERY_KEY } from "../api";

export function useRevokeProvider(resetCountry: boolean) {
  const revokeMutation = useRevokeProviderMutation();
  const queryClient = useQueryClient();
  const { storage, accountsData, setActiveCountry } = useContext(DataContext);

  const { showToast } = useToast();

  return async (provider: string) => {
    try {
      await revokeMutation.mutateAsync(provider);
      storage?.deleteRemovedBank(provider, accountsData);

      if (resetCountry) {
        setActiveCountry("all");
        storage?.changeCountry("all");
      }

      await queryClient.invalidateQueries({ queryKey: [TOKENS_QUERY_KEY] });
      showToast({ variant: "success", title: t`Banka byla odpojena`, description: t`Odpojení banky bylo úspěšné.` });
    } catch (e) {
      showToast({ variant: "attention", title: t`Odpojení banky se nezdařilo`, description: t`Zkuste to znovu.` });
    }
  };
}
