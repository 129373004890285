import { createContext, ReactNode } from "react";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";

const noop = () => {};

interface Context {
  params: URLSearchParams;
  setParams: SetURLSearchParams;
}

// eslint-disable-next-line react-refresh/only-export-components
export const UrlParamsContext = createContext<Context>({
  params: new URLSearchParams(),
  setParams: noop,
});

interface Props {
  children: ReactNode;
}

export function UrlParamsProvider({ children }: Props) {
  const [params, setParams] = useSearchParams();

  return <UrlParamsContext.Provider value={{ params, setParams }}>{children}</UrlParamsContext.Provider>;
}
