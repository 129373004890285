import { ChartLegend } from "../ChartLegend.tsx";
import { axisClasses, chartsAxisHighlightClasses, LineChart } from "@mui/x-charts";
import { axisColor, noDataMessage, primaryChartColor, tickColor } from "../../utils/chartsInfo.ts";
import { ChartTooltip } from "../ChartsTooltip.tsx";
import { formatNumber } from "../../../../utils/format.ts";
import { MultipleValuesChartTooltip } from "../MultipleValuesChartTooltip.tsx";
import { TabData } from "./BalanceDevelopment.tsx";
import { useContext } from "react";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { BalanceDevelopmentData, getAccountBalanceKey, getAccountBalanceValue } from "./utils.ts";
import { getAccountName } from "../../../../utils/tableHelpers.tsx";

interface Props {
  accountDisplay: TabData[number]["value"];
  dataset: BalanceDevelopmentData[];
}

export function BalanceDevelopmentChart({ accountDisplay, dataset }: Props) {
  const { balanceOfActiveAccounts, listOfActiveAccounts, accountsData, activeCurrency } = useContext(DataContext);
  const margin = balanceOfActiveAccounts > 1000000 ? 68 : 53;
  return (
    <div>
      {accountDisplay === "detail" && (
        <ChartLegend
          legendItems={listOfActiveAccounts.map((accountKey) => ({
            label: getAccountName(accountsData[accountKey]),
            color: accountsData[accountKey].color,
          }))}
        />
      )}

      <LineChart
        key={`${dataset.length}_${accountDisplay}_${balanceOfActiveAccounts}`}
        height={442}
        dataset={dataset}
        series={
          accountDisplay === "total"
            ? [
                {
                  dataKey: "balance",
                  curve: "linear",
                  color: primaryChartColor,
                  highlightScope: { fade: "global", highlight: "series" },
                },
              ]
            : listOfActiveAccounts.map((accountKey) => ({
                dataKey: getAccountBalanceKey(accountKey),
                curve: "linear",
                color: accountsData[accountKey].color,
                highlightScope: { fade: "global", highlight: "series" },
              }))
        }
        xAxis={[{ dataKey: "label", scaleType: "band" }]}
        bottomAxis={{ disableTicks: true, disableLine: false }}
        leftAxis={{ disableTicks: true, disableLine: true }}
        margin={{ left: margin, right: 20 }}
        grid={{ horizontal: true }}
        sx={{
          [`& .${chartsAxisHighlightClasses.root}`]: { stroke: tickColor },
          [`& .${axisClasses.root}`]: {
            fill: tickColor,
            [`& .${axisClasses.tickLabel}`]: { fill: tickColor },
            [`& .${axisClasses.line}`]: { stroke: axisColor },
          },
        }}
        slotProps={{ noDataOverlay: { message: noDataMessage() } }}
        slots={{
          axisContent: ({ dataIndex }) => {
            if (typeof dataIndex !== "number") return null;
            return accountDisplay === "total" ? (
              <ChartTooltip
                value={formatNumber(dataset[dataIndex]?.balance)}
                label={dataset[dataIndex].label}
                currency={activeCurrency}
              />
            ) : (
              <MultipleValuesChartTooltip
                label={dataset[dataIndex].label}
                values={listOfActiveAccounts
                  .map((accountKey) => ({
                    value: getAccountBalanceValue(accountKey, dataset[dataIndex]),
                    color: accountsData[accountKey].color,
                  }))
                  .reverse()}
                currency={activeCurrency}
              />
            );
          },
        }}
      />
    </div>
  );
}
