import { useState } from "react";
import { Close } from "@radix-ui/react-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan as faSolidTrashCan, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Modal } from "../../../../components";
import { formatNumber } from "../../../../utils/format.ts";
import css from "./BankCard.module.css";
import { Tooltip } from "../../../../components";
import { t, Trans } from "@lingui/macro";
import { useRevokeProvider } from "../../../../hooks/useRevokeProvider.ts";

interface Props {
  provider: string;
  logo: string;
  bankName: string;
  balance: number;
  currency: string;
}
export function BankCard({ logo, bankName, balance, currency, provider }: Props) {
  const revokeBankProvider = useRevokeProvider(true);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  return (
    <Card variant={"medium"} size={"sm"} className={"w-[12.8rem] group"}>
      <div className={"flex justify-between items-start"}>
        <div className={"h-12 flex items-center"}>
          <img src={logo} alt={`${bankName} icon`} className={"h-10 max-w-32 object-contain"} />
        </div>
        <Tooltip
          trigger={
            <button className={"text-gray-100 group-hover:text-gray-600"} onClick={() => setIsRemoveModalOpen(true)}>
              <FontAwesomeIcon className={"w-5 h-5"} icon={faTrashCan} />
            </button>
          }
          text={t`Odpojit banku`}
        />

        <Modal
          title={t`Odpojit banku`}
          icon={
            <FontAwesomeIcon
              className={css.icon}
              icon={faSolidTrashCan}
              width={"22px"}
              height={"22px"}
              color={"#d92d20"}
            />
          }
          open={isRemoveModalOpen}
          onOpenChange={setIsRemoveModalOpen}
        >
          <p className={css.modalText}>
            <Trans>Opravdu chcete odpojit {bankName}.</Trans>
          </p>
          <p className={css.modalText}>
            <Trans>V budoucnu si ji můžete opět kdykoliv připojit.</Trans>
          </p>

          <div className={css.buttonGroup}>
            <Close asChild>
              <Button variant={"default"} color={"gray"} size={"md"}>
                <Trans>Zavřít</Trans>
              </Button>
            </Close>

            <Button
              variant={"destructive"}
              color={"primary"}
              size={"md"}
              endIcon={<FontAwesomeIcon icon={faSolidTrashCan} />}
              onClick={() => {
                void revokeBankProvider(provider);
              }}
            >
              <Trans>Odpojit banku</Trans>
            </Button>
          </div>
        </Modal>
      </div>

      <div className={"mt-3"}>
        <p className={"typo-text-sm-regular mb-1.5"}>
          <Trans>Disponibilní zůstatek:</Trans>
        </p>
        <div className={"flex justify-between typo-text-lg-bold"}>
          <span>{formatNumber(balance)}</span>
          <span>{currency}</span>
        </div>
      </div>
    </Card>
  );
}
