import { Flag } from "../../../../components";
import { clsx } from "clsx";
import { getPreferredLanguage, setPreferredLanguage } from "../../../../locales/utils.ts";

export function ToggleLanguage({ className }: { className?: string }) {
  const language = getPreferredLanguage();
  const isCzech = language === "cs";

  return (
    <button
      className={clsx(
        "flex gap-1.5 items-center hover:underline hover:decoration-[2px] hover:decoration-gray-300 hover:underline-offset-[14px] ",
        className,
      )}
      onClick={() => {
        setPreferredLanguage(isCzech ? "en" : "cs");
        location.reload();
      }}
    >
      <Flag className={"w-[1.25rem] h-[1.25rem]"} code={isCzech ? "GB" : "CZ"} />
      <p className={"typo-text-sm-medium text-gray-500"}>{isCzech ? "English" : "Česky"}</p>
    </button>
  );
}
