import { MenuDesktop } from "./components/MenuDesktop/MenuDesktop.tsx";
import { MenuMobile } from "./components/MenuMobile/MenuMobile.tsx";
import logo from "../../assets/logo.svg";
import css from "./Header.module.css";
import { UserMenu } from "./components/UserMenu/UserMenu.tsx";
import { ERoutes } from "../../router/routes.ts";
import { useKeycloakUserQuery } from "../../auth/keycloak.ts";
import { useWindowSize } from "@uidotdev/usehooks";
import { useQueryClient } from "@tanstack/react-query";
import { ACCOUNT_LIST_KEY, ACCOUNT_TRANSACTIONS_KEY, TOKENS_QUERY_KEY } from "../../api";
import { NavLink } from "react-router-dom";
import { t } from "@lingui/macro";
import { ToggleLanguage } from "./components/ToggleLanguage/ToggleLanguage.tsx";

export type MenuItem = { text: string; href: ERoutes };

export function Header() {
  const menuItems: MenuItem[] = [
    { text: t`Dashboard`, href: ERoutes.Home },
    { text: t`Transakce`, href: ERoutes.Transactions },
    { text: t`Endpointy`, href: ERoutes.Requests },
  ];

  const size = useWindowSize();
  const isDesktop = Boolean((size.width ?? 0) > 640);

  const client = useQueryClient();
  const invalidate = () => {
    void client.invalidateQueries({ queryKey: [TOKENS_QUERY_KEY] });
    void client.invalidateQueries({ queryKey: [ACCOUNT_LIST_KEY] });
    void client.invalidateQueries({ queryKey: [ACCOUNT_TRANSACTIONS_KEY] });
  };

  const userInfo = useKeycloakUserQuery();

  if (userInfo.isPending) return null;

  const firstName = userInfo.data?.firstName ?? "";
  const fullName = `${userInfo.data?.firstName} ${userInfo.data?.lastName}`;
  const email = userInfo.data?.username ?? "";
  return (
    <header className={css.header}>
      <div className={css.container}>
        <NavLink to={ERoutes.Home}>
          <img
            className={"cursor-pointer"}
            title={t`Aktualizovat data`}
            src={logo}
            alt="Finbricks logo"
            width={65.6}
            height={40}
            onClick={invalidate}
          />
        </NavLink>

        {isDesktop && <MenuDesktop items={menuItems} />}
        {!isDesktop && <MenuMobile email={email} fullName={fullName} items={menuItems} />}
        {isDesktop ? (
          <div className={"flex gap-8 items-center"}>
            <ToggleLanguage />
            <UserMenu fullName={fullName} email={email} firstName={firstName} />
          </div>
        ) : (
          <UserMenu fullName={fullName} email={email} firstName={firstName} />
        )}
      </div>
    </header>
  );
}
