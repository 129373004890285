import { useAuth } from "../../auth/hooks/useAuth.ts";
import { Navigate } from "react-router-dom";
import { ERoutes } from "../../router/routes.ts";
import logo from "../../assets/logo.svg";
import { Button, Divider, LinkButton } from "../../components";
import { Footer } from "../../layout/Footer/Footer.tsx";
import css from "./Login.module.css";
import { Trans } from "@lingui/macro";
import { ToggleLanguage } from "../../layout/Header/components/ToggleLanguage/ToggleLanguage.tsx";

export function Login() {
  const { login, isAuthenticated } = useAuth();

  if (isAuthenticated) return <Navigate to={ERoutes.Home} replace={true} />;

  return (
    <>
      <header className={css.header}>
        <img src={logo} alt="Finbricks logo" width={65.6} height={40} />
        <ToggleLanguage />
      </header>
      <main className={css.container}>
        <div className={css.loginSection}>
          <img src={logo} alt="Finbricks logo" width={100} height={61} />
          <h1 className={css.loginHeadline}>
            <Trans>Finbricks Multibank Demo</Trans>
          </h1>
          <p className={css.loginText}>
            <Trans>Přihlaste se do systému a zjistěte výhody Multibanku</Trans>
          </p>
          <Button variant={"default"} color={"primary"} size={"sm"} onClick={login}>
            <Trans>Přihlásit</Trans>
          </Button>
        </div>

        <Divider className={css.divider} />
        <div>
          <h3 className={css.linkHeadline}>
            <Trans>Proč používat Finbricks?</Trans>
          </h3>
          <p className={css.linkText}>
            <Trans>
              Zjistěte sami:{" "}
              <LinkButton variant={"default"} color={"primary"} size={"md"} className={"inline"}>
                Finbricks.com
              </LinkButton>
            </Trans>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
}
