import { BalanceOverviewChart } from "./BalanceOverviewChart.tsx";
import { BalanceOverviewLegend } from "../BalanceOverviewLegend/BalanceOverviewLegend.tsx";
import { StructuredData } from "../../../../dataProviders/DataProvider.tsx";
import { CountryAndCurrencySelect } from "../CountryAndCurrencySelect.tsx";
import { Trans } from "@lingui/macro";

interface Props {
  structuredData: StructuredData;
  activeCurrency: string;
}

export function BalanceOverview({ structuredData, activeCurrency }: Props) {
  return (
    <section className={"pb-[4.5rem]"}>
      <div className={"flex flex-wrap gap-4 justify-between mt-28 mb-8"}>
        <h3 className={"typo-display-sm-semibold"}>
          <Trans>Přehled zůstatku na účtech</Trans>
        </h3>
        <CountryAndCurrencySelect />
      </div>

      <div className={"flex flex-col gap-8 justify-between items-center lg:flex-row lg:gap-16"}>
        <BalanceOverviewChart structuredData={structuredData} currency={activeCurrency} />
        <BalanceOverviewLegend structuredData={structuredData} currency={activeCurrency} />
      </div>
    </section>
  );
}
