import { ErrorBoundary } from "@sentry/react";
import { Router } from "./router/Router.tsx";
import { ApiProvider } from "./api";
import { QueryError } from "./components/QueryError.tsx";
import { ToastProvider } from "./components/Toast/ToastProvider.tsx";
import { t } from "@lingui/macro";
import { LocalesProvider } from "./locales/LocalesProvider.tsx";

function App() {
  return (
    <LocalesProvider>
      <ErrorBoundary fallback={<QueryError message={t`Omlouváme se, došlo k neočekávané chybě.`} grow={true} />}>
        <ApiProvider>
          <ToastProvider>
            <Router />
          </ToastProvider>
        </ApiProvider>
      </ErrorBoundary>
    </LocalesProvider>
  );
}

export default App;
