import * as isoCountries from "i18n-iso-countries";
import csLocale from "i18n-iso-countries/langs/cs.json";
import enLocale from "i18n-iso-countries/langs/en.json";
import { getPreferredLanguage } from "../locales/utils.ts";

isoCountries.registerLocale(csLocale);
isoCountries.registerLocale(enLocale);
export const getNameFromCountryCode = (countryCode?: string) => {
  const language = getPreferredLanguage();
  if (!countryCode) return;
  return isoCountries.getName(countryCode, language);
};
