import { AccountTransactionsResponse } from "../api";
import { AccountWithColor } from "../dataProviders/DataProvider.tsx";
import { t } from "@lingui/macro";

export function readableDirection(value?: string): string {
  if (!value) return "";
  if (value === "DBIT") return t`Odchozí`;
  return t`Příchozí`;
}

export function getCounterPartyInfo(
  transaction: AccountTransactionsResponse["transactions"][number] & {
    date: Date;
    account: string;
  },
) {
  const isDebtor = transaction.creditDebitIndicator === "DBIT";
  const parties = transaction.entryDetails?.transactionDetails?.relatedParties;
  const account = parties?.[isDebtor ? "creditorAccount" : "debtorAccount"];
  const owner = parties?.[isDebtor ? "creditor" : "debtor"];

  return {
    iban: account?.identification?.iban,
    name: owner?.name,
    accountName: account?.name,
  };
}

export function getIdentification(
  transaction: AccountTransactionsResponse["transactions"][number] & {
    date: Date;
    account: string;
  },
) {
  const rawIdentification =
    transaction.entryDetails?.transactionDetails?.remittanceInformation?.structured?.creditorReferenceInformation
      ?.reference;
  return rawIdentification?.split(/[/,]/);
}

export function getAccountName(account: AccountWithColor) {
  return account.accountName || account.productName || account.identification?.iban || "";
}
