import { useContext, useMemo } from "react";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { getNameFromCountryCode } from "../../../../utils/getLocale.ts";
import { Badge, ScrollArea } from "../../../../components";
import { Direction } from "./LastTransactions.tsx";
import css from "./LastTransactionsTable.module.css";
import { formatDayWithFullYear, formatNumber } from "../../../../utils/format.ts";
import {
  getAccountName,
  getCounterPartyInfo,
  getIdentification,
  readableDirection,
} from "../../../../utils/tableHelpers.tsx";
import { TransactionsDataContext } from "../../../../dataProviders/TransactionsDataProvider.tsx";

export function LastTransactionsTable({ direction }: { direction: Direction }) {
  const { accountsData } = useContext(DataContext);
  const { transactionsOfActiveAccounts } = useContext(TransactionsDataContext);

  const filteredData = useMemo(() => {
    if (direction === "in") {
      const filteredData = [];
      for (const transaction of transactionsOfActiveAccounts) {
        if (transaction.creditDebitIndicator === "CRDT") filteredData.push(transaction);
      }
      return filteredData.slice(0, 5);
    }

    if (direction === "out") {
      const filteredData = [];
      for (const transaction of transactionsOfActiveAccounts) {
        if (transaction.creditDebitIndicator === "DBIT") filteredData.push(transaction);
      }
      return filteredData.slice(0, 5);
    }

    return transactionsOfActiveAccounts.slice(0, 5);
  }, [direction, transactionsOfActiveAccounts]);

  return (
    <ScrollArea orientation={"horizontal"} className={"max-w-[75rem]"}>
      <table className={"min-w-full border-b-2 border-gray-200"}>
        <tbody>
          {filteredData.map((record, index) => {
            const account = accountsData[record.account];
            const badgeText = readableDirection(record.creditDebitIndicator);
            const counterParty = getCounterPartyInfo(record);
            const identification = getIdentification(record);
            const message =
              record.entryDetails?.transactionDetails?.additionalRemittanceInformation ||
              record.entryDetails?.transactionDetails?.remittanceInformation?.unstructured;
            return (
              <tr key={index} className={css.tableRow}>
                <td className={css.tableCell}>
                  <div className={css.account}>
                    <img
                      src={account.bank.identification.logoUrl}
                      alt={`${account.bank.identification.name} icon`}
                      className={css.accountLogo}
                    />
                    <div>
                      <p className={css.accountAccountName}>{getAccountName(account)}</p>
                      <p className={css.accountCountry}>
                        {getNameFromCountryCode(account.bank.identification.countryCode)}
                      </p>
                    </div>
                  </div>
                </td>
                <td className={css.tableCell}>
                  <p className={css.date}>{formatDayWithFullYear(record.date)}</p>
                </td>
                <td className={css.tableCell}>
                  <Badge
                    text={badgeText}
                    size={"sm"}
                    color={record.creditDebitIndicator === "CRDT" ? "secondary" : "blue"}
                  />
                </td>
                <td className={css.tableCell}>
                  {!!counterParty.accountName && <p className={css.counterPartyAccount}>{counterParty.accountName}</p>}
                  {!!counterParty.name && <p className={css.counterPartyOwner}>{counterParty.name}</p>}
                  {!!counterParty.iban && <p className={css.counterPartyIban}>{counterParty.iban}</p>}
                </td>
                <td className={css.tableCell}>
                  {!!identification &&
                    identification.map((symbol) => (
                      <div key={symbol} className={css.identification}>
                        {symbol}
                      </div>
                    ))}
                </td>
                <td className={css.tableCell}>{!!message && <p className={css.message}>{message}</p>}</td>
                <td className={css.tableCell}>
                  <p className={css.value}>{formatNumber(record.amount.value)}</p>
                </td>
                <td className={css.tableCell}>
                  <p className={css.currency}>{record.amount.currency}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ScrollArea>
  );
}
