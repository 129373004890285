import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Title } from "../../../components/Title/Title.tsx";
import { IconButton, ScrollArea } from "../../../components";
import { AddBankModal } from "./AddBankModal/AddBankModal.tsx";
import { CountryLegend } from "./CountryLegend/CountryLegend.tsx";
import { BankCard } from "./BankCard/BankCard.tsx";
import { BalanceOverview } from "./BalanceOverview/BalanceOverview.tsx";
import { DataContext } from "../../../dataProviders/DataProvider.tsx";
import { Tooltip } from "../../../components";
import { sortCountries } from "../utils/sortCountries.ts";
import { t } from "@lingui/macro";

export function BanksSection() {
  const { structuredData, activeCurrency } = useContext(DataContext);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const sortedCountries = sortCountries(Object.keys(structuredData));
  return (
    <div
      className={"bg-gray-25 shadow-[0px_2px_18px_0px_rgba(16,_24,_40,_0.05)]"}
      style={{ clipPath: "inset(0px -10px -10px -10px)" }}
    >
      <section className={"app-container"}>
        <div className={"pt-16 mb-8"}>
          <Title />
        </div>

        <div className={"flex gap-6"}>
          <Tooltip
            trigger={
              <IconButton
                variant={"default"}
                color={"primary"}
                size={"lg"}
                className="px-6 py-10 h-[11.125rem] rounded-2xl"
                icon={<FontAwesomeIcon className="w-[1.5rem] h-[1.5rem]" icon={faPlus} width={24} height={24} />}
                onClick={() => setIsAddModalOpen(true)}
              ></IconButton>
            }
            text={t`Přidat banku`}
          />
          <AddBankModal
            key={isAddModalOpen ? "open" : "close"}
            open={isAddModalOpen}
            onOpenChange={setIsAddModalOpen}
          />
          <div className={"overflow-hidden"}>
            <ScrollArea orientation={"horizontal"}>
              <div className={"flex gap-6"}>
                {sortedCountries.map((country) => {
                  return (
                    <div key={country}>
                      <CountryLegend countryCode={country} />
                      <div className={"flex gap-6 mt-4"}>
                        {Object.keys(structuredData[country]).map((currency) =>
                          Object.keys(structuredData[country][currency].providers).map((provider) => (
                            <BankCard
                              key={provider}
                              logo={
                                structuredData[country][currency].providers[provider].info.identification.logoUrl ?? ""
                              }
                              provider={provider}
                              currency={currency}
                              balance={structuredData[country][currency].providers[provider].balance}
                              bankName={structuredData[country][currency].providers[provider].info.identification.name}
                            />
                          )),
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
        </div>

        <BalanceOverview structuredData={structuredData} activeCurrency={activeCurrency} />
      </section>
    </div>
  );
}
