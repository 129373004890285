import { LargestPaymentChart } from "./LargestPaymentChart.tsx";
import { LargestPaymentTable } from "./LargestPaymentTable.tsx";
import { useContext, useMemo, useState } from "react";
import { DataContext } from "../../../../dataProviders/DataProvider.tsx";
import { primaryChartColor } from "../../utils/chartsInfo.ts";
import { TransactionsDataContext } from "../../../../dataProviders/TransactionsDataProvider.tsx";
import { t } from "@lingui/macro";

const LIMIT = 5;

export type TransactionType = "amount" | "quantity";

export function LargestOutgoingPayment() {
  const { activeCurrency } = useContext(DataContext);
  const { outgoingPaymentCount, setOutgoingPaymentTimeRange, outgoingPaymentTimeRange } =
    useContext(TransactionsDataContext);

  const [dataType, setDataType] = useState<TransactionType>("amount");

  const sortedOutgoingKeys = useMemo(() => {
    const keys = Object.keys(outgoingPaymentCount).sort((a, b) => {
      return outgoingPaymentCount[b][dataType] - outgoingPaymentCount[a][dataType];
    });
    return keys.slice(0, keys.length < LIMIT ? keys.length : LIMIT);
  }, [outgoingPaymentCount, dataType]);

  return (
    <section className={"grid grid-cols-1 gap-8 lg:grid-cols-12"}>
      <LargestPaymentChart
        dataKeys={sortedOutgoingKeys}
        dataType={dataType}
        setDataType={setDataType}
        timeRange={outgoingPaymentTimeRange}
        setTimeRange={setOutgoingPaymentTimeRange}
        paymentCount={outgoingPaymentCount}
        currency={activeCurrency}
        headline={t`Protiúčty s nejvíce transakcemi - Odchozí`}
        color={primaryChartColor}
      />
      <LargestPaymentTable
        dataKeys={sortedOutgoingKeys}
        dataType={dataType}
        paymentCount={outgoingPaymentCount}
        currency={activeCurrency}
        headline={dataType === "amount" ? t`Nejvyšší celkové výdaje` : t`Nejvyšší počet transakcí`}
      />
    </section>
  );
}
