import { useState } from "react";
import { boolean, enums, is, number, record, string, type } from "superstruct";
import { AES, enc } from "crypto-ts";
import sha256 from "sha256";
import { useKeycloakUserQuery } from "../auth/keycloak.ts";
import { WidgetKey, widgetKeys } from "../pages/Dashboard/Dashboard.tsx";
import { AccountsData } from "../dataProviders/DataProvider.tsx";
import { logError } from "../utils/sentry.ts";

type StoredLayoutWidget = { isVisible: boolean; order: number };

interface Storage {
  country?: string;
  currency?: string;
  activeAccounts?: Record<string, boolean>;
  activeBanks?: Record<string, boolean>;
  layoutWidgets?: Record<WidgetKey, StoredLayoutWidget>;
}

export function useUserPreferencesStorage() {
  const { data } = useKeycloakUserQuery();

  const encryptionKey = data?.id;
  const key = hashKey(data?.id);

  const [storedValue, setStoredValue] = useState<Storage>(() => {
    if (!key || !encryptionKey) return {};

    const value = localStorage.getItem(key);

    if (!value) return {};

    return decryptValue(value, encryptionKey);
  });

  const changeStoredValue = (storage: Storage) => {
    if (!key || !encryptionKey) return;

    localStorage.setItem(key, encryptValue(storage, encryptionKey));
    setStoredValue(storage);
  };

  const clearStoredValue = () => {
    if (!key) return;

    localStorage.clear();
  };

  const changeCountry = (country: Storage["country"]) => {
    changeStoredValue({ ...storedValue, country });
  };
  const changeCurrency = (currency: Storage["currency"]) => {
    changeStoredValue({ ...storedValue, currency });
  };
  const changeActiveAccounts = (activeIds: string[]) => {
    const activeAccounts = { ...storedValue.activeAccounts };
    Object.keys(activeAccounts).forEach((key) => (activeAccounts[key] = activeIds.includes(key)));
    activeIds.forEach((key) => (activeAccounts[key] = true));
    changeStoredValue({ ...storedValue, activeAccounts });
  };
  const changeActiveBanks = (activeIds: string[]) => {
    const activeBanks = { ...storedValue.activeBanks };
    Object.keys(activeBanks).forEach((key) => (activeBanks[key] = activeIds.includes(key)));
    activeIds.forEach((key) => (activeBanks[key] = true));
    changeStoredValue({ ...storedValue, activeBanks });
  };

  const deleteRemovedBank = (provider: string, accountsData: AccountsData) => {
    const activeBanks = { ...storedValue.activeBanks };
    for (const key in activeBanks) {
      if (key === provider) delete activeBanks[key];
    }
    const activeAccounts = { ...storedValue.activeAccounts };
    for (const key in accountsData) {
      if (accountsData[key].bank.identification.paymentProvider === provider) {
        delete activeAccounts[key];
      }
    }

    changeStoredValue({ ...storedValue, activeBanks, activeAccounts });
  };

  const changeLayoutWidgets = (layoutWidgets: Storage["layoutWidgets"]) => {
    changeStoredValue({ ...storedValue, layoutWidgets });
  };

  return {
    storedValue,
    clearStoredValue,
    changeCountry,
    changeCurrency,
    changeActiveAccounts,
    changeActiveBanks,
    changeLayoutWidgets,
    deleteRemovedBank,
  };
}

function hashKey(key?: string): string | undefined {
  return key ? sha256(key) : undefined;
}

function encryptValue(value: Storage, encryptionKey: string): string {
  return AES.encrypt(JSON.stringify(value), encryptionKey).toString();
}

function decryptValue(value: string, encryptionKey: string): Storage {
  try {
    const json = JSON.parse(AES.decrypt(value, encryptionKey).toString(enc.Utf8));

    return {
      country: is(json["country"], string()) ? json["country"] : undefined,
      currency: is(json["currency"], string()) ? json["currency"] : undefined,
      activeAccounts: is(json["activeAccounts"], record(string(), boolean())) ? json["activeAccounts"] : undefined,
      activeBanks: is(json["activeBanks"], record(string(), boolean())) ? json["activeBanks"] : undefined,
      layoutWidgets: is(
        json["layoutWidgets"],
        record(enums(widgetKeys), type({ isVisible: boolean(), order: number() })),
      )
        ? json["layoutWidgets"]
        : undefined,
    };
  } catch (e) {
    logError(e);
    return {};
  }
}
