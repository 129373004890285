import { LinkButton } from "../../../../components";
import css from "./LogoutButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../../auth/hooks/useAuth.ts";
import { Trans } from "@lingui/macro";

export function LogoutButton() {
  const { logout } = useAuth();
  return (
    <LinkButton
      className={css.dropdownItem}
      variant={"default"}
      color={"secondary"}
      size={"md"}
      endIcon={<FontAwesomeIcon icon={faArrowRightFromBracket} width={20} height={20} />}
      onClick={logout}
    >
      <Trans>Odhlásit</Trans>
    </LinkButton>
  );
}
