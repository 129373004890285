import { createContext, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import {
  useAccountsQuery,
  usePaymentProvidersQuery,
  GetAccountListWithBalanceResponse,
  PaymentProvidersResponse,
} from "../api";
import { TransactionsParam } from "../api/hooks/useTransactionsQuery.ts";
import { getNameFromCountryCode } from "../utils/getLocale.ts";
import { accountColors, bankColors } from "../pages/Dashboard/utils/chartsInfo.ts";
import { useUserPreferencesStorage } from "../hooks/useUserPreferencesStorage.ts";
import { useAccountCheck } from "../hooks/useAccountCheck.ts";
import { useRequests } from "./useRequests.ts";
import { useTokens } from "./useTokens.ts";
import { logError } from "../utils/sentry.ts";
import { t } from "@lingui/macro";

interface Props {
  children: ReactNode;
}

export type AccountWithColor = Exclude<GetAccountListWithBalanceResponse, undefined>[number] & {
  color: string;
  bank: PaymentProvidersResponse["banks"][number];
};

type ProviderData = {
  accounts: Record<string, AccountWithColor>;
  info: Exclude<PaymentProvidersResponse["banks"], undefined>[number];
  balance: number;
  color: string;
};

type Provider = {
  providers: Record<string, ProviderData>;
  balance: number;
};

export type AccountsData = Record<string, AccountWithColor>;

export type PaymentCount = {
  [key: string]: { amount: number; quantity: number; label: string };
};

// Record<country, Record<currency, Record<bank, ProviderData>>>;
export type StructuredData = Record<string, Record<string, Provider>>;

export type Requests = {
  paymentProviders: string;
  tokens: string;
  accounts: { paymentProvider: string; label: string; response: string }[];
};

const defaultRequests: Requests = { paymentProviders: "", tokens: "", accounts: [] };

interface Context {
  paymentProviders: PaymentProvidersResponse["banks"];
  tokens: string[];
  structuredData: StructuredData;
  areAccountQueriesPending: boolean;
  areAccountQueriesError: boolean;
  accountQueryInvalidProviders: string[];
  transactionsParams: TransactionsParam[];
  countriesSelectAllOption: { label: string; value: string }[];
  currenciesSelect: { label: string; value: string }[];
  activeCountry: string;
  setActiveCountry: (activeCountry: string) => void;
  activeCurrency: string;
  setActiveCurrency: (activeCurrency: string) => void;
  activeBanks: string[];
  setActiveBanks: (banks: string[]) => void;
  activeAccounts: string[];
  setActiveAccounts: (activeAccount: string[]) => void;
  toggleActiveBank: (bank: string) => void;
  toggleActiveAccount: (account: string) => void;
  selectedCountries: string[];
  listOfActiveAccounts: string[];
  balanceOfActiveAccounts: number;
  accountsData: AccountsData;
  storage?: ReturnType<typeof useUserPreferencesStorage>;
  requests: Requests;
}

// eslint-disable-next-line react-refresh/only-export-components
export const DataContext = createContext<Context>({
  paymentProviders: [],
  tokens: [],
  structuredData: {},
  areAccountQueriesPending: true,
  areAccountQueriesError: false,
  accountQueryInvalidProviders: [],
  transactionsParams: [],
  countriesSelectAllOption: [],
  currenciesSelect: [],
  activeCountry: "all",
  setActiveCountry: () => {},
  activeCurrency: "",
  setActiveCurrency: () => {},
  activeBanks: [],
  setActiveBanks: () => {},
  activeAccounts: [],
  setActiveAccounts: () => {},
  toggleActiveBank: () => {},
  toggleActiveAccount: () => {},
  selectedCountries: [],
  listOfActiveAccounts: [],
  balanceOfActiveAccounts: 0,
  accountsData: {},
  requests: defaultRequests,
});

export function DataProvider({ children }: Props) {
  const { requests, setTokensRequests, setPaymentProvidersRequests, setAccountsRequests } = useRequests();
  const { showNotification } = useAccountCheck();

  // API responses
  const paymentProvidersResponse = usePaymentProvidersQuery();
  const paymentProvidersResponseData = useMemo(() => {
    if (!paymentProvidersResponse.data?.banks) return [];

    setPaymentProvidersRequests(paymentProvidersResponse.data);

    return paymentProvidersResponse.data.banks;
  }, [paymentProvidersResponse.data, setPaymentProvidersRequests]);

  //Edited paymentProviders
  const providersWithKeys = useMemo(() => {
    return paymentProvidersResponseData.reduce(
      (acc, cur) => ({ ...acc, [cur.identification.paymentProvider]: cur }),
      {} as Record<string, Exclude<PaymentProvidersResponse["banks"], undefined>[number]>,
    );
  }, [paymentProvidersResponseData]);

  const { tokens, isTokensResponsePending, isTokenResponseError } = useTokens(setTokensRequests);

  const accountsResponse = useAccountsQuery(tokens, { enabled: !!tokens.length });
  const accountQueryInvalidProviders = useMemo(() => {
    return accountsResponse.errors.reduce((acc, cur) => (cur ? [...acc, cur.paymentProvider] : acc), [] as string[]);
  }, [accountsResponse.errors]);

  const storage = useUserPreferencesStorage();
  const storageRef = useRef(storage);
  storageRef.current = storage;

  const [activeCountry, setActiveCountry] = useState<string>(storage.storedValue.country ?? "all");
  const [activeCurrency, setActiveCurrency] = useState<string>(storage.storedValue.currency ?? "");

  const [activeBanks, setActiveBanks] = useState<string[]>([]);
  const [activeAccounts, setActiveAccounts] = useState<string[]>([]);

  const toggleActiveBank = (bank: string) => {
    setActiveBanks((prevState) => {
      const newState = prevState.includes(bank) ? prevState.filter((_bank) => _bank !== bank) : [...prevState, bank];
      storage.changeActiveBanks(newState);
      return newState;
    });
  };

  const toggleActiveAccount = (account: string) => {
    setActiveAccounts((prevState) => {
      const newState = prevState.includes(account)
        ? prevState.filter((_account) => _account !== account)
        : [...prevState, account];
      storage.changeActiveAccounts(newState);
      return newState;
    });
  };

  // Structured Data
  const { transactionsParams, structuredData, countriesSelect, accountsData } = useMemo(() => {
    const structuredData: StructuredData = {};
    const countriesSelect: { label: string; value: string }[] = [];
    const banksDefaultValues: string[] = [];
    const accountsDefaultValues: string[] = [];
    const transactionsParams: TransactionsParam[] = [];
    const accountsData: AccountsData = {};

    if (accountsResponse.isPending || !tokens.length)
      return { structuredData, transactionsParams, countriesSelect, accountsData };

    try {
      for (let i = 0; i < tokens.length; i++) {
        const providerKey = tokens[i];

        const bankProvider = providersWithKeys[providerKey];
        const country = bankProvider.identification.countryCode;

        if (!structuredData[country]) {
          countriesSelect.push({ label: getNameFromCountryCode(country) ?? "", value: country });
          structuredData[country] = {};
        }

        if (storageRef.current.storedValue.activeBanks?.[bankProvider.identification.paymentProvider] !== false) {
          banksDefaultValues.push(bankProvider.identification.paymentProvider);
        }

        const providersAccounts = accountsResponse.data[i] ?? [];

        showNotification(!!providersAccounts.length, bankProvider.identification.name);

        setAccountsRequests(accountsResponse.data[i] ?? [], bankProvider, providerKey);

        for (const [index, account] of providersAccounts.entries()) {
          if (!account.id || !account.currency) continue;

          const currency = account.currency;

          if (account?.id && storageRef.current.storedValue.activeAccounts?.[account.id] !== false) {
            accountsDefaultValues.push(account.id);
          }
          if (!structuredData[country][currency]) {
            structuredData[country][currency] = { providers: {}, balance: 0 };
          }

          const structuredBank = structuredData[country][currency].providers[providerKey];

          const balance = (structuredBank?.balance ?? 0) + account.balance;

          const augmentedAccount: AccountWithColor = { ...account, color: accountColors[i][index], bank: bankProvider };
          accountsData[account.id] = augmentedAccount;

          structuredData[country][currency].providers[providerKey] = {
            info: bankProvider,
            accounts: { ...(structuredBank?.accounts ?? {}), [account.id]: augmentedAccount },
            balance: balance,
            color: bankColors[i],
          };
          structuredData[country][currency].balance += account.balance;
          transactionsParams.push({ token: providerKey, id: account.id });
        }
      }

      setActiveBanks(banksDefaultValues);
      storageRef.current.changeActiveBanks(banksDefaultValues);
      setActiveAccounts(accountsDefaultValues);
      window.setTimeout(() => storageRef.current.changeActiveAccounts(accountsDefaultValues), 0);

      return { structuredData, transactionsParams, countriesSelect, accountsData };
    } catch (error) {
      logError(error);
      return { structuredData, transactionsParams, countriesSelect, accountsData };
    }
  }, [
    accountsResponse.data,
    accountsResponse.isPending,
    providersWithKeys,
    setAccountsRequests,
    showNotification,
    tokens,
  ]);

  const currenciesSelect = useMemo<{ label: string; value: string }[]>(() => {
    if (!Object.keys(structuredData).length) return [];
    const currencies: string[] = [];

    try {
      for (const country of Object.keys(structuredData).filter((c) => activeCountry === "all" || activeCountry === c)) {
        for (const currency of Object.keys(structuredData[country])) {
          if (!currencies.includes(currency)) currencies.push(currency);
        }
      }

      if (!currencies.includes(activeCurrency)) {
        setActiveCurrency(currencies[0]);
        storageRef.current.changeCurrency(currencies[0]);
      }

      return currencies.map((c) => ({ value: c, label: c }));
    } catch (error) {
      logError(error);
      return [];
    }
  }, [activeCountry, activeCurrency, structuredData]);

  useEffect(() => {
    if (activeCurrency || !Object.keys(structuredData).length) return;

    const country = activeCountry === "all" ? Object.keys(structuredData)[0] : activeCountry;

    setActiveCurrency(Object.keys(structuredData[country])[0]);
    storageRef.current.changeCurrency(Object.keys(structuredData[country])[0]);
  }, [activeCountry, activeCurrency, structuredData]);

  const countriesSelectAllOption = useMemo(
    () => [{ label: t`Vše`, value: "all" }, ...countriesSelect],
    [countriesSelect],
  );

  const areAccountQueriesPending =
    paymentProvidersResponse.isPending || isTokensResponsePending || accountsResponse.isPending;

  const areAccountQueriesError = paymentProvidersResponse.isError || isTokenResponseError || accountsResponse.isError;

  const selectedCountries = useMemo(() => {
    return activeCountry === "all" ? Object.keys(structuredData) : [activeCountry];
  }, [activeCountry, structuredData]);

  const { listOfActiveAccounts, balanceOfActiveAccounts } = useMemo(() => {
    const activeAccountKeys: string[] = [];
    let balanceOfActiveAccounts = 0;

    if (accountsResponse.isPending || !Object.keys(structuredData).length || !activeCurrency) {
      return { listOfActiveAccounts: activeAccountKeys, balanceOfActiveAccounts };
    }

    try {
      for (const country of selectedCountries) {
        for (const bankKey of Object.keys(structuredData[country][activeCurrency]?.providers ?? [])) {
          if (!activeBanks.includes(bankKey)) continue;

          for (const accountKey of Object.keys(structuredData[country][activeCurrency].providers[bankKey].accounts)) {
            if (activeAccounts.includes(accountKey)) {
              balanceOfActiveAccounts +=
                structuredData[country][activeCurrency].providers[bankKey].accounts[accountKey].balance;
              activeAccountKeys.push(accountKey);
            }
          }
        }
      }
      return { listOfActiveAccounts: activeAccountKeys, balanceOfActiveAccounts };
    } catch (error) {
      logError(error);
      return { listOfActiveAccounts: activeAccountKeys, balanceOfActiveAccounts };
    }
  }, [accountsResponse.isPending, activeAccounts, activeBanks, activeCurrency, selectedCountries, structuredData]);

  return (
    <DataContext.Provider
      value={{
        structuredData,
        paymentProviders: paymentProvidersResponseData,
        tokens,
        areAccountQueriesPending,
        areAccountQueriesError,
        accountQueryInvalidProviders,
        transactionsParams,
        countriesSelectAllOption,
        currenciesSelect,
        activeCurrency,
        setActiveCurrency,
        activeCountry,
        setActiveCountry,
        activeBanks,
        setActiveBanks,
        activeAccounts,
        setActiveAccounts,
        toggleActiveBank,
        toggleActiveAccount,
        selectedCountries,
        listOfActiveAccounts,
        balanceOfActiveAccounts,
        accountsData,
        storage,
        requests,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
