import { useContext, useState, useMemo, ReactNode } from "react";
import { DataContext } from "../../dataProviders/DataProvider.tsx";
import { Button } from "../../components";
import { NoAvailableBanksPage } from "../NoAvailableBanksPage/NoAvailableBanksPage.tsx";
import { Loader } from "../../components/Loader/Loader.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { BalanceOverviewTransactions } from "./components/BalanceOverviewTransactions/BalanceOverviewTransactions.tsx";
import { LastTransactions } from "./components/LastTransactions/LastTransactions.tsx";
import { LargestOutgoingPayment } from "./components/LargestPayment/LargestOutgoingPayment.tsx";
import { IncomesAndExpensesChart } from "./components/IncomesAndExpensesChart/IncomesAndExpensesChart.tsx";
import { DashboardSettings } from "./components/DashboardSettings/DashboardSettings.tsx";
import { LargestIncomingPayment } from "./components/LargestPayment/LargestIncomingPayment.tsx";
import { BalanceDevelopment } from "./components/BalanceDevelopment/BalanceDevelopment.tsx";
import { QueryError } from "../../components/QueryError.tsx";
import { BanksSection } from "./components/BanksSection.tsx";
import { TransactionsDataContext } from "../../dataProviders/TransactionsDataProvider.tsx";
import { t, Trans } from "@lingui/macro";

export type LayoutWidget = { isVisible: boolean; order: number };

export type WidgetKey = (typeof widgetKeys)[number];

type VisibleComponents = Record<WidgetKey, LayoutWidget>;

export function Dashboard() {
  const { balanceOfActiveAccounts, areAccountQueriesError, areAccountQueriesPending, activeCurrency, storage, tokens } =
    useContext(DataContext);

  const { areTransactionsQueriesPending, areTransactionsQueriesError } = useContext(TransactionsDataContext);
  const [visibleComponents, setVisibleComponents] = useState<VisibleComponents>(
    storage?.storedValue.layoutWidgets ?? layoutWidgets,
  );

  const changeVisibleComponents = (value: VisibleComponents) => {
    setVisibleComponents(value);
    storage?.changeLayoutWidgets(value);
  };

  const widgetKeys = useMemo(() => {
    const keys = Object.keys(visibleComponents) as WidgetKey[];
    return keys.sort((a, b) => visibleComponents[a].order - visibleComponents[b].order);
  }, [visibleComponents]);

  if (areAccountQueriesError) {
    return <QueryError message={t`Nepodařilo se načíst informace o Vašich účtech`} grow={true} />;
  }

  if (areAccountQueriesPending || (tokens.length && !activeCurrency)) {
    return <Loader grow={true} message={t`Načítání informací o bankách`} />;
  }

  if (!tokens.length) return <NoAvailableBanksPage />;

  return (
    <div>
      <BanksSection />

      {areTransactionsQueriesPending && (
        <Loader grow={true} message={t`Načítání informací o transakcích`} className={"py-48"} />
      )}

      {areTransactionsQueriesError && (
        <QueryError message={t`Nepodařilo se načíst informace o Vašich transakcích`} grow={true} />
      )}

      {!areTransactionsQueriesPending && !areTransactionsQueriesError && (
        <section className={"app-container"}>
          <BalanceOverviewTransactions currency={activeCurrency} balance={balanceOfActiveAccounts} />
          <div className={"flex justify-between items-center flex-wrap mt-28 mb-8"}>
            <h2 className={"typo-display-md-semibold text-gray-900"}>Dashboard</h2>
            <DashboardSettings
              widgetKeys={widgetKeys}
              setVisibleComponents={changeVisibleComponents}
              visibleComponents={visibleComponents}
              trigger={
                <Button variant={"default"} color={"tertiary"} size={"md"} endIcon={<FontAwesomeIcon icon={faGear} />}>
                  <Trans>Nastavení dashboardu</Trans>
                </Button>
              }
            />
          </div>
          <div className={"flex flex-col gap-8 mb-14"}>
            {widgetKeys.map((key) => {
              if (visibleComponents[key].isVisible) return <div key={key}>{componentMap[key]}</div>;
              return null;
            })}
          </div>
        </section>
      )}
    </div>
  );
}

const layoutWidgets: VisibleComponents = {
  lastTransactions: { isVisible: true, order: 1 },
  paymentsIncoming: { isVisible: true, order: 2 },
  paymentsOutgoing: { isVisible: true, order: 3 },
  incomesAndExpenses: { isVisible: true, order: 4 },
  balanceDevelopment: { isVisible: true, order: 5 },
};

const componentMap: Record<WidgetKey, ReactNode> = {
  paymentsIncoming: <LargestIncomingPayment />,
  paymentsOutgoing: <LargestOutgoingPayment />,
  lastTransactions: <LastTransactions />,
  incomesAndExpenses: <IncomesAndExpensesChart />,
  balanceDevelopment: <BalanceDevelopment />,
};

// eslint-disable-next-line react-refresh/only-export-components
export const widgetKeys = [
  "lastTransactions",
  "paymentsIncoming",
  "paymentsOutgoing",
  "incomesAndExpenses",
  "balanceDevelopment",
] as const;
