import { Link } from "react-router-dom";
import { usePagination } from "./usePagination.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import css from "./Pagination.module.css";
import { t } from "@lingui/macro";

type Props = {
  control: ReturnType<typeof usePagination>;
  className?: string;
};

export function Pagination({ control, className }: Props) {
  const { paginationLinks, currentPage, totalPages } = control;

  return (
    <>
      <div className={clsx(css.container, className)}>
        <Link
          aria-label={t`předchozí stránka`}
          className={clsx(css.icon, currentPage === 0 && css.iconDisabled)}
          to={`?page=${currentPage}`}
        >
          <FontAwesomeIcon className={"w-5"} icon={faChevronLeft} />
        </Link>
        {paginationLinks?.map(({ href, label }, index) => {
          return !href ? (
            <p className={clsx(css.buttonDots)} key={label + String(index)} style={{ display: "inline" }}>
              . . .
            </p>
          ) : (
            <Link
              key={label + String(index)}
              to={href}
              className={clsx(css.button, typeof label === "number" && label - 1 === currentPage && css.buttonActive)}
            >
              {label}
            </Link>
          );
        })}
        <Link
          aria-label={t`následující stránka`}
          className={clsx(css.icon, currentPage === totalPages - 1 && css.iconDisabled)}
          to={`?page=${currentPage + 2}`}
        >
          <FontAwesomeIcon className={"w-5"} icon={faChevronRight} />
        </Link>
      </div>
    </>
  );
}
