import { t } from "@lingui/macro";
import { Select } from "../../../../components";
import { SelectDisplay } from "./utils.ts";

interface Props {
  timeDisplay: SelectDisplay[number]["value"];
  changeTimeDisplay: (value: SelectDisplay[number]["value"]) => void;
  timeRange: string;
  changeTimeRange: (value: string) => void;
}

export function BalanceDevelopmentSelect({ timeDisplay, changeTimeDisplay, timeRange, changeTimeRange }: Props) {
  const selectDisplay: SelectDisplay = [
    { value: "daily", label: t`Denní přehled` },
    { value: "weekly", label: t`Týdenní přehled` },
  ];

  const selectData = [
    { value: "7", label: t`7 dní` },
    { value: "30", label: t`30 dní` },
    { value: "90", label: t`90 dní` },
    { value: "custom", label: t`Vlastní` },
  ];

  return (
    <div className={"flex gap-3 md:justify-end xl:col-start-11 xl:col-span-5"}>
      <Select
        value={timeDisplay}
        variant={"primary"}
        onChange={changeTimeDisplay}
        options={selectDisplay}
        className={"lg:order-2"}
      />
      <Select
        value={timeRange}
        variant={"primary"}
        onChange={changeTimeRange}
        options={selectData}
        className={"lg:order-1"}
      />
    </div>
  );
}
