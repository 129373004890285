import Logo from "../../assets/logo.svg";
import css from "./Footer.module.css";
import { Trans } from "@lingui/macro";

export function Footer() {
  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <p className={css.mainText}>
          <Trans>
            Tato webová stránka slouží výhradně pro představení a vyzkoušení našich služeb. V případě jakýchkoli dotazů
            či nejasností se na nás neváhejte obrátit na{" "}
            <a href={`mailto:support@finbricks.com`} className={css.link}>
              support@finbricks.com
            </a>
          </Trans>
        </p>
        <div className={css.finbricksSection}>
          <img className={css.logo} src={Logo} alt="Finbricks Logo" width={66} height={40} />
          <p className={css.text}>
            <Trans>
              Driven by <span className={css.textHighlight}>Finbricks</span>
            </Trans>
          </p>
        </div>
      </div>
    </footer>
  );
}
