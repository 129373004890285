import { useTokensMutation, STATUS_QUERY_KEY } from "../../api";
import { useForm, SubmitHandler } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Divider, LinkButton, Input } from "../../components";
import logo from "../../assets/logo.svg";
import css from "./PromoCodePage.module.css";
import { Loader } from "../../components/Loader/Loader.tsx";
import { useToast } from "../../components/Toast/useToast.ts";
import { t, Trans } from "@lingui/macro";

type Input = {
  promoCode: string;
};

export function PromoCodePage() {
  const { mutateAsync, isPending } = useTokensMutation();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>();

  const { showToast } = useToast();

  const onSubmit: SubmitHandler<Input> = async (data) => {
    try {
      await mutateAsync({ ...data });
      await queryClient.invalidateQueries({ queryKey: [STATUS_QUERY_KEY] });
    } catch (e) {
      showToast({ variant: "attention", title: t`Nebylo možné ověřit promo kód`, description: t`Zkuste to znovu.` });
    }
  };

  if (isPending) return <Loader grow={true} message={t`Ověřujeme promo kód`} />;

  return (
    <main className={css.main}>
      <div className={css.formSection}>
        <img src={logo} alt="Finbricks logo" width={100} height={61} />

        <h1 className={css.formHeadline}>
          <Trans>Finbricks Multibank Demo</Trans>
        </h1>
        <p className={css.formText}>
          <Trans>Multibank lze použit pouze po zadání promo kódu</Trans>
        </p>

        <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={t`Promo kód`}
            variant={"high-contrast"}
            placeholder={t`Vložte promo kód`}
            required={true}
            containerProps={{ className: "mb-6 w-full max-w-sm" }}
            {...register("promoCode", {
              required: t`Toto pole je povinné`,
            })}
            isError={!!errors.promoCode?.message}
            messages={errors.promoCode?.message ? [{ variant: "error", text: errors.promoCode.message }] : undefined}
          />
          <Button variant={"default"} size={"sm"} color={"primary"} type="submit">
            <Trans>Potvrdit</Trans>
          </Button>
        </form>
      </div>

      <Divider className={css.divider} />
      <div>
        <h3 className={css.linkHeadline}>
          <Trans>Proč používat Finbricks?</Trans>
        </h3>
        <p className={css.linkText}>
          <Trans>
            Zjistěte sami:{" "}
            <LinkButton variant={"default"} color={"primary"} size={"md"} className={"inline"}>
              Finbricks.com
            </LinkButton>
          </Trans>
        </p>
      </div>
    </main>
  );
}
