import { clsx } from "clsx";
import { getAccountName, getCounterPartyInfo, readableDirection } from "../../../../utils/tableHelpers.tsx";
import { getNameFromCountryCode } from "../../../../utils/getLocale.ts";
import { Badge, ScrollArea } from "../../../../components";
import { formatDayWithFullYear, formatNumber } from "../../../../utils/format.ts";
import { TransactionTableHeader } from "./TransactionTableHeader.tsx";
import { AccountsData } from "../../../../dataProviders/DataProvider.tsx";
import { Column } from "../../Transactions.tsx";
import { Transaction } from "../../../../dataProviders/TransactionsDataProvider.tsx";
import css from "../../Transactions.module.css";

interface Props {
  transactions: Transaction[];
  accountsData: AccountsData;
  sortTransactions: (column: Column) => void;
  sortBy?: string;
  sort: string;
}

export function TransactionsTable({ transactions, accountsData, sortBy, sort, sortTransactions }: Props) {
  return (
    <ScrollArea orientation={"horizontal"} className={"max-w-[75rem] mt-8"}>
      <table className={"w-full border-b-2"}>
        <TransactionTableHeader sort={sort} sortTransactions={sortTransactions} sortBy={sortBy} />
        <tbody>
          {transactions.map((record, index) => {
            const account = accountsData[record.account];

            if (!account) return null;

            const badgeText = readableDirection(record.creditDebitIndicator);
            const counterParty = getCounterPartyInfo(record);
            const message =
              record.entryDetails?.transactionDetails?.additionalRemittanceInformation ||
              record.entryDetails?.transactionDetails?.remittanceInformation?.unstructured;

            return (
              <tr key={index} className={css.tableRow}>
                <td className={css.tableCell}>
                  <div className={css.account}>
                    <img
                      src={account.bank.identification.logoUrl}
                      alt={`${account.bank.identification.name} icon`}
                      className={css.accountLogo}
                    />
                    <div>
                      <p className={css.accountAccountName}>{getAccountName(account)}</p>
                      <p className={css.accountCountry}>
                        {getNameFromCountryCode(account.bank.identification.countryCode)}
                      </p>
                    </div>
                  </div>
                </td>
                <td className={css.tableCell}>
                  <p className={css.date}>{formatDayWithFullYear(record.date)}</p>
                </td>
                <td className={clsx(css.tableCell, "text-center")}>
                  {
                    <Badge
                      text={badgeText}
                      size={"md"}
                      color={record.creditDebitIndicator === "CRDT" ? "secondary" : "blue"}
                    />
                  }
                </td>
                <td className={css.tableCell}>
                  {!!counterParty.accountName && <p className={css.counterPartyAccount}>{counterParty.accountName}</p>}
                  {!!counterParty.name && <p className={css.counterPartyOwner}>{counterParty.name}</p>}
                  {!!counterParty.iban && <p className={css.counterPartyIban}>{counterParty.iban}</p>}
                </td>
                <td className={css.tableCell}>
                  {record.identification.map((symbol) => (
                    <div key={symbol} className={css.identification}>
                      {symbol}
                    </div>
                  ))}
                </td>
                <td className={css.tableCell}>{!!message && <p className={css.message}>{message}</p>}</td>
                <td className={css.tableCell}>
                  <p className={css.value}>{formatNumber(record.amount.value)}</p>
                </td>
                <td className={clsx(css.tableCell, "text-center")}>
                  <p className={css.currency}>{record.amount.currency}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ScrollArea>
  );
}
