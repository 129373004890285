import { ReactNode } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faEye, faEyeSlash, faGripLines } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Modal, IconButton } from "../../../../components";
import { LayoutWidget, WidgetKey } from "../../Dashboard.tsx";
import css from "./DashboardSettings.module.css";
import { Tooltip } from "../../../../components";
import { t } from "@lingui/macro";

interface Props {
  trigger: ReactNode;
  widgetKeys: WidgetKey[];
  visibleComponents: Record<WidgetKey, LayoutWidget>;
  setVisibleComponents: (visibleComponents: Record<WidgetKey, LayoutWidget>) => void;
}

export function DashboardSettings({ trigger, visibleComponents, setVisibleComponents, widgetKeys }: Props) {
  const widgetNameMap: Record<WidgetKey, string> = {
    lastTransactions: t`Poslední transakce`,
    paymentsIncoming: t`Protiúčty s nejvíce transakcemi - Příchozí`,
    paymentsOutgoing: t`Protiúčty s nejvíce transakcemi - Odchozí`,
    incomesAndExpenses: t`Příjmy a výdaje`,
    balanceDevelopment: t`Vývoj zůstatku v čase`,
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    const newState = { ...visibleComponents };
    const movedItem = newState[draggableId as WidgetKey];
    const newDestination = destination.index + 1;
    const keys = Object.keys(newState);
    keys.forEach((key) => {
      if (key === draggableId) return;
      if (key !== draggableId) {
        if (movedItem.order < newState[key as WidgetKey].order && newDestination >= newState[key as WidgetKey].order) {
          newState[key as WidgetKey].order = newState[key as WidgetKey].order - 1;
        }
        if (movedItem.order > newState[key as WidgetKey].order && newDestination <= newState[key as WidgetKey].order) {
          newState[key as WidgetKey].order = newState[key as WidgetKey].order + 1;
        }
      }
    });

    movedItem.order = newDestination;

    setVisibleComponents(newState);
  };
  return (
    <Modal
      title={t`Nastavení dashboardu`}
      trigger={trigger}
      icon={<FontAwesomeIcon className={"mr-4 w-[22px] h-[22px]"} icon={faGear} color={"#3518ec"} />}
    >
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Droppable droppableId={"dashboard-settings"}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {widgetKeys.map((key, index) => {
                const components = visibleComponents[key];
                return (
                  <Draggable draggableId={key} isDragDisabled={!components.isVisible} index={index} key={key}>
                    {(provided, snapshot) => {
                      if (snapshot.isDragging && provided.draggableProps.style) {
                        // @ts-expect-error reason fix position in modal, type definitions are not aware of that
                        provided.draggableProps.style.left = provided.draggableProps.style.offsetLeft;
                        // @ts-expect-error reason fix position in modal, type definitions are not aware of that
                        provided.draggableProps.style.top = provided.draggableProps.style.offsetTop;
                      }
                      return (
                        <div {...provided.draggableProps} ref={provided.innerRef} className={"flex items-center gap-4"}>
                          <div className={clsx(css.draggableItem, !components.isVisible && css.draggableItemDisabled)}>
                            <p className={"typo-display-xxs-semibold"}>{widgetNameMap[key]}</p>
                            <Tooltip
                              trigger={
                                <IconButton
                                  id={key}
                                  icon={<FontAwesomeIcon icon={components.isVisible ? faEye : faEyeSlash} />}
                                  onClick={() => {
                                    const newState = { ...visibleComponents };
                                    newState[key].isVisible = !newState[key].isVisible;
                                    setVisibleComponents(newState);
                                  }}
                                  variant={"default"}
                                  size={"sm"}
                                  color={"gray"}
                                />
                              }
                              text={components.isVisible ? t`Skrýt` : t`Zobrazit`}
                            />
                          </div>
                          <IconButton
                            {...provided.dragHandleProps}
                            disabled={!components.isVisible}
                            className={"mb-6"}
                            variant={"default"}
                            color={"secondary"}
                            size={"sm"}
                            title={t`Změnit pořadí`}
                            icon={<FontAwesomeIcon icon={faGripLines} />}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
}
